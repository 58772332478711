<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>礼品信息</el-breadcrumb-item>
            <el-breadcrumb-item>添加/编辑</el-breadcrumb-item>

          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">

        <el-form-item label="类型">
          <el-radio-group v-model="form.itype">
            <el-radio :label=0>满减</el-radio>
            <el-radio :label=1>折扣券</el-radio>

          </el-radio-group>
        </el-form-item>

        <el-form-item label="序号" :error="$v.form.sorder.$error ? '请输入序号' : ''">
          <el-input v-model.number="form.sorder" type="number"></el-input>
        </el-form-item>
        <el-form-item label="优惠券标题" :error="$v.form.name.$error ? '请输入优惠券标题' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="有效期类型">
          <el-radio-group v-model="form.endtype">
            <el-radio :label=0>按日期</el-radio>
            <el-radio :label=1>按天数</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="優惠券有效期" v-if="form.endtype==0">
          <el-date-picker v-model="form.endtime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="選擇日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="有效期天数" v-if="form.endtype==1">
          <el-input v-model.number="form.endday"></el-input>
        </el-form-item>

        <el-form-item label="折扣" v-if="form.itype==1">
          <el-input-number v-model="form.money" :precision="2" :step="0.01"></el-input-number>
        </el-form-item>
        <el-form-item label="优惠券价值" v-if="form.itype==0">
          <el-input-number v-model="form.money" :precision="2" :step="0.01"></el-input-number>
        </el-form-item>

        <el-form-item label="满减使用规则" :error="$v.form.fullmoney.$error ? '请输入满减金额' : ''">
          <el-input-number v-model="form.fullmoney" :precision="2" :step="0.01"></el-input-number>0表示无限制
        </el-form-item>
        <el-form-item label="发行量" :error="$v.form.couponnum.$error ? '请输发行量' : ''">
          <el-input v-model.number="form.couponnum"></el-input>
        </el-form-item>
        <el-form-item label="每人限制领取数量">
          <el-input v-model.number="form.limitcount"><template slot="append">0不限制数量</template></el-input>
        </el-form-item>
        <el-form-item label="首页状态">
          <el-radio-group v-model="form.isshow">
            <el-radio :label=0>隐藏</el-radio>
            <el-radio :label=1>显示</el-radio>

          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.states">
            <el-radio :label=0>禁用</el-radio>
            <el-radio :label=1>启用</el-radio>

          </el-radio-group>
        </el-form-item>
        <el-form-item label="积分兑换">
          <el-radio-group v-model="form.isintegral">
            <el-radio :label=1>开启</el-radio>
            <el-radio :label=0>关闭</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="所需积分" v-if="form.isintegral==1">
          <el-input v-model.number="form.integral"></el-input>
        </el-form-item>
        <el-form-item label="使用规则">
          <el-input type="textarea" v-model="form.des" :rows="20"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from '@/components/tinymce'
import ImageUpload from '@/components/imageUpload'
import FileUpload from '@/components/fileUpload'
import { editRequest, getdatilRequest } from '@/api/fj_coupon'
import { required } from 'vuelidate/lib/validators'
import utils from '@/utils/util'
export default {
  name: 'Articletypeedit',

  components: {
    'image-upload': ImageUpload,
    'file-upload': FileUpload,
    tinymce: Tinymce,
  },

  data() {
    return {
      classify: [],
      shopdata: [],
      form: {
        id: 0,
      },
      productidtext: [],
      educationdata: [],
      constellationdata: [],
      zodiacdata: [],
      typeId: 0,
    }
  },

  methods: {
    back() {
      this.$router.back()
    },
    imageUpload(url) {
      this.form.giftimg = url
    },

    getDetail() {
      getdatilRequest({
        Id: this.form.id,
      }).then((res) => {
        if (this.form.id == 0) {
        }
        this.form = res.data
      })
    },

    save() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form))
        params.giftimg = utils.removeUrl(params.giftimg)
        editRequest(params).then((res) => {
          if (res.code == 1) {
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  },

  created() {
    this.form.id = this.$route.query['id']

    this.getDetail()
  },

  validations: {
    form: {
      sorder: {
        required,
      },
      fullmoney: {
        required,
      },
      money: {
        required,
      },

      couponnum: {
        required,
      },

      states: {
        required,
      },
      name: { required },
    },
  },
}
</script> 

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>